import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import {
    getCentralAdminId,
    getPickerDetailEmbedURL, getToken,
} from "../redux/Thunk/WarehouseInsightsThunk";
import { SESSION_KEY } from "../components/Common/helpers";
//import {RouteListDetails} from 'duoiotweb';
import Cookies from "js-cookie";

function RouteDashBoardDetails({
    getPickerDetailEmbedURLFromProps,
    pickerDetailURLFromPros,
    loadingPickerURLFromProps,
}) {

  var restoredSession = JSON.parse(localStorage.getItem(SESSION_KEY));
  const [embedUrl, setEmbedUrl] = useState(null)
  const [indicatorOn, setIndicatorOn] = useState(false);
  var apiTriggered = false

    useEffect(() => {
        setCookieUserData();
         //document.addEventListener("message", onMessageReceive, true);

        return () => {
            // Unbind the event listener on clean up
            //document.removeEventListener("message",onMessageReceive);
        }


  }, []);

    let onMessageReceive = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        console.log("----------------");
        console.log(event);
        console.log("----------");
    }

  let setCookieUserData = async ()=>{
      let routeDBUrl = process.env.REACT_APP_AMI_CLOUD_FRONT_API_ENDPOINT+"/dashboard";
      let token = await getToken();


      console.log(process.env.REACT_APP_AMI_CLOUD_FRONT_API_ENDPOINT);
      setEmbedUrl(routeDBUrl);



  }

  let testMessage = (msg)=>{
        console.log(msg);
  }


  return (
    <>
    <Box>
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', height: '90vh' }}>
               {/*<RouteListDetails />*/}
            </div>
        </div>
    </Box>
    </>
)
}

const pickerDetailStateToProps = (state) => {
  return {
    pickerDetailURLFromPros: state?.warehouseInsightsReducer?.pickerDetailURL,
    loadingPickerURLFromProps: state?.warehouseInsightsReducer?.loadingPickerURL,
  };
};

const pickerDetailDispatchToProps = (dispatch) => {
  return {
    getPickerDetailEmbedURLFromProps: () => dispatch(getPickerDetailEmbedURL()),
  };
};

export default connect(
    pickerDetailStateToProps,
    pickerDetailDispatchToProps
)(RouteDashBoardDetails);
