import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerAndLocationsDataLoad: false,
  customerAndLocationsData: [],
  selectedLocationsList: [],
  selectedCustomer: "",
  selectedLocation: "",
  listofRoutesDataLoad: false,
  listofRoutesData: {},
  routesSummaryDataLoad: false,
  routesSummaryData: "",
  assetsSummaryDataLoad: false,
  assetsSummaryData: "",
  liftSummaryDataLoad: false,
  liftSummaryData: "",
  liftListDataLoad: false,
  liftListData: [],
  updateStatus: false,
  routesAlertsDataLoad: false,
  routesAlertsData: "",
  locationAlertsDataLoad: false,
  locationAlertsData: "",
  loadingLiftInfoData: false,
  liftInfoData: "",
  loadingLiftAlertData: false,
  liftAlertData: "",
  liftAlertPageData: "",
  loadingLiftMaintenanceData: false,
  liftMaintenanceData: "",
  loadingLiftTasksList: false,
  liftTasksList: "",
  loadingLiftPartsList: false,
  liftPartsList: "",
  addMaintenanceStatus: false,
  selectedCustomerListData: "",
  selectedLocationListData: "",
  applicationIds: [],
  applicationIdsLoad: false,
  userRoleName: "",
  routeStartTime:"",
  routeDCTime:"",
  updatePinStatus: false,
  loadingFirstRouteDetails: false,
  loadingFinalRouteDetails: false,
  routeDetailsData: [],
  loadingHeartBeats: false,
  heartBeatsData: []
};

export const RoutesReducer = createSlice({
  name: "Routes",
  initialState,
  reducers: {
    setLoadingCustomerAndLocationsData: (state, action) => {
      state.customerAndLocationsDataLoad = action.payload;
    },
    setCustomerAndLocations: (state, action) => {
      state.customerAndLocationsData = action.payload;
      state.selectedLocationsList = action.payload[0].locations;
      state.selectedCustomer = action.payload[0].customerId;
      state.selectedLocation = action.payload[0].locations[0].locationId;
      state.selectedCustomerListData = action.payload[0];
      state.selectedLocationListData = action.payload[0].locations[0];
    },
    setLoadingListofRoutesData: (state, action) => {
      state.listofRoutesDataLoad = action.payload;
    },
    setListofRoutes: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.listofRoutesData = action.payload.sort((a, b) => a.routeName.localeCompare(b.routeName));
      }
    },
    setRouteStartTime:(state, action) => {
      if(Array.isArray(action.payload)){
        var firstSort = 'startTime'
        state.routeStartTime = "";
        const assendingDataTime = action.payload.filter(date => date[firstSort] !== null).sort((a, b) => new Date(a[firstSort]) - new Date(b[firstSort]))
        var utcTime = assendingDataTime.length > 0 && new Date(assendingDataTime[0].startTime)
        if(utcTime){
            utcTime.setHours(utcTime.getHours()-1);
            const date = new Date(utcTime);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            state.routeStartTime = date;
        }
      }
    },
    setRouteDCTime:(state, action) => {
      if(Array.isArray(action.payload)){
        state.routeDCTime = "";
        if(action.payload.length > 0){
          state.routeDCTime = action.payload[0]?.dcTime;
        }
      }
    },
    setLoadingRoutesSummaryData: (state, action) => {
      state.routesSummaryDataLoad = action.payload;
    },
    setRoutesSummary: (state, action) => {
      state.routesSummaryData = action.payload;
    },
    setLoadingAssetSummaryData: (state, action) => {
      state.assetsSummaryDataLoad = action.payload;
    },
    setAssetsSummary: (state, action) => {
      state.assetsSummaryData = action.payload;
    },
    setLoadingLiftSummaryData: (state, action) => {
      state.liftSummaryDataLoad = action.payload;
    },
    setLiftsSummary: (state, action) => {
      state.liftSummaryData = action.payload;
    },
    setLoadingLiftListData: (state, action) => {
      state.liftListDataLoad = action.payload;
    },
    setLiftsList: (state, action) => {
      state.liftListData = action.payload;
    },
    setUpdateStatus: (state, action) => {
      state.updateStatus = action.payload
    },
    setLoadingRouteAlertsData: (state, action) => {
      state.routesAlertsDataLoad = action.payload;
    },
    setRouteAlertsList: (state, action) => {
      state.routesAlertsData = action.payload.alerts
    },
    setLoadingLocationAlertsData: (state, action) => {
      state.locationAlertsDataLoad = action.payload;
    },
    setLocationAlertsList: (state, action) => {
      state.locationAlertsData = action.payload
    },
    setLoadingLiftInfoData: (state, action) => {
      state.loadingLiftInfoData = action.payload;
    },
    setLiftInfoData: (state, action) => {
      state.liftInfoData = action.payload
    },
    setLoadingLiftAlertData: (state, action) => {
      state.loadingLiftAlertData = action.payload;
    },
    setLiftAlertData: (state, action) => {
      state.liftAlertData = action.payload
    },
    setLiftAlertPageData: (state, action) => {
      state.liftAlertPageData = action.payload
    },
    setLoadingLiftMaintenanceData: (state, action) => {
      state.loadingLiftMaintenanceData = action.payload;
    },
    setLiftMaintenanceData: (state, action) => {
      state.liftMaintenanceData = action.payload
    },
    setLoadingLiftTasksList: (state, action) => {
      state.loadingLiftTasksList = action.payload;
    },
    setLiftTasksList: (state, action) => {
      state.liftTasksList = action.payload
    },
    setLoadingLiftPartsList: (state, action) => {
      state.loadingLiftPartsList = action.payload;
    },
    setLiftPartsList: (state, action) => {
      state.liftPartsList = action.payload
    },
    setAddMaintenanceStatus: (state, action) => {
      state.addMaintenanceStatus = action.payload
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomerListData = action.payload
      state.selectedLocationListData = action.payload.locations[0]
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocationListData = action.payload
    },
    setApplicationIdsLoad: (state, action) => {
      state.applicationIdsLoad = action.payload;
    },
    setApplicationIds:(state, action) =>{
      state.applicationIds = action.payload
    },
    setUserRoleName:(state, action) =>{
      state.userRoleName = action.payload
    },
    setUpdatePinStatus: (state, action) => {
      state.updatePinStatus = action.payload
    },
    setLoadingFirstRouteDetails: (state, action) => {
      state.loadingFirstRouteDetails = action.payload;
    },
    setLoadingFinalRouteDetails: (state, action) => {
      state.loadingFinalRouteDetails = action.payload;
    },
    setRouteDetailsData: (state, action) => {
      state.routeDetailsData = action.payload;
    },
    setLoadingHeartBeats: (state, action) => {
      state.loadingHeartBeats = action.payload;
    },
    setHeartBeatsData: (state, action) => {
      state.heartBeatsData = action.payload;
    },
    setloadingOperationURL: (state, action) => {
      state.loadingOperationURL = action.payload;
    },
    setOperationsPerformanceQURL: (state, action) => {
      if(action.payload){
        state.operationsPerformanceQURL = action.payload['embed-url'];
      }else{
        state.operationsPerformanceQURL = "";
      }
    }
  },
});

export const {
  setLoadingCustomerAndLocationsData,
  setCustomerAndLocations,
  setListofRoutes,
  setRouteStartTime,
  setRouteDCTime,
  setLoadingListofRoutesData,
  setLoadingRoutesSummaryData,
  setRoutesSummary,
  setLoadingAssetSummaryData,
  setAssetsSummary,
  setLoadingLiftSummaryData,
  setLiftsSummary,
  setLoadingLiftListData,
  setLiftsList,
  setUpdateStatus,
  setLoadingRouteAlertsData,
  setRouteAlertsList,
  setLoadingLocationAlertsData,
  setLocationAlertsList,
  setLoadingLiftInfoData,
  setLiftInfoData,
  setLoadingLiftAlertData,
  setLiftAlertData,
  setLiftAlertPageData,
  setLoadingLiftMaintenanceData,
  setLiftMaintenanceData,
  setLoadingLiftTasksList,
  setLiftTasksList,
  setLoadingLiftPartsList,
  setLiftPartsList,
  setAddMaintenanceStatus,
  setSelectedCustomer,
  setSelectedLocation,
  setApplicationIds,
  setApplicationIdsLoad,
  setUserRoleName,
  setUpdatePinStatus,
  setLoadingFirstRouteDetails,
  setLoadingFinalRouteDetails,
  setRouteDetailsData,
  setLoadingHeartBeats,
  setloadingOperationURL,
  setOperationsPerformanceQURL,
  setHeartBeatsData
} = RoutesReducer.actions;

export default RoutesReducer.reducer;
