export class Constants {


    static locationId = ""
    static customerId = "";
    static dcId = "";
    static DC_MODE = "edge";
    static DC_MODE_EDGE = "edge"
    static DC_MODE_CLOUD = "cloud"
    static RPC_ADMIN = "RpcAdmin"
    static RPC_READ_ONLY = "RpcReadOnly"
    static PORTRAIT = "portrait";
    static LANDSCAPE = "landscape";
    static isAuditLoaded = false;

    static MISSING = "Missing";
    static EXTRA = "Extra";
    static CAN_NOT_CONFIRM = "Can’t confirm";
    static AUDITED = "Audited";
    static NO_ERROR = "No error";
    static UN_TRAINED = "UNTRAINED";
    static SCREEN_NAME = "";
    static PALLET_REVIEW = "PalletReview";
    static PALLET_ARCHIVE = "PalletArchive";

    static palletArchiveData = []

    static IS_IMAGE_CORRECTION_ENABLED = false;
    static userId = ""
    static userRole = "";
    static userApps = "";

    static ROUTE_SELECTION_EVENT = "ROUTE_SELECTION_EVENT";
    static ROUTE_DETAILS_BACK_EVENT = "ROUTE_DETAILS_BACK_EVENT";
    static ASSET_HANDLE_CLICK_EVENT = "ASSET_HANDLE_CLICK_EVENT";
    static SELECT_DATE = null;
    static ROUTE_IN_PROGRESS = "In progress";
    static ROUTE_IN_COMPLETED = "Completed";
    static RPC_ADMIN = "RpcAdmin";
    static RPC_READ_ONLY = "RpcReadOnly";
    static CUSTOMER_ADMIN = "CustomerAdmin";
    static LOCATION_ADMIN = "LocationAdmin";
    static LIFT_MAINTENANCE_EVENT = "LIFT_MAINTENANCE_EVENT";
    static LIFT_ADD_MAINTENANCE_EVENT = "LIFT_ADD_MAINTENANCE_EVENT";

    static SUCCESS = "success";
    static ERROR = "error";

    static locationId = ""
    static customerId = "";

}
