import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import {
    setOperationsPerformanceURL,
    setPickerDetailURL,
    setLoadingloadingOperationURL,
    setLoadingPickerURL,
    setOperationsPerformanceQURL,
} from "../reducers/warehouseInsights/WarehouseInsightsReducer";
import Cookies from 'js-cookie';
import {Constants} from "../../utils/Constants";
import {
    setApplicationIds,
    setApplicationIdsLoad,
    setUserRoleName
} from "../../diot/redux/reducers/Routes/RoutesReducer";

const API_ENDPOINT = process.env.REACT_APP_QC_BOT_API_ENDPOINT;
const AMI_API_ENDPOINT = process.env.REACT_APP_AMI_API_ENDPOINT;
const CENTRAL_ADMIN_ENDPOINT = process.env.REACT_APP_CENTRAL_ADMIN_API;


export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    // console.log("session", session.accessToken.jwtToken);
    return session.accessToken.jwtToken;
  } catch {
    return null;
  }
};

export const getCentralAdminId = async () =>{
  try {
      if(Constants.userId){
          return  Constants.userId;
      }else{
        const user = await Auth.currentUserInfo();
          let userId = user.attributes['custom:central_user_id'];
          Cookies.set("loggedInUserId", userId);
          Constants.userId = userId;
          return userId;//user.attributes['custom:central_user_id']
      }

  } catch {
    return null;
  }
};

export const getOperationalPerformanceEmbedURL = createAsyncThunk(
  "WarehouseInsightsThunk/getOperationalPerformanceEmbedURL",
  async (data, { dispatch }) => {
    // console.log("getPalletArchiveEarliestDate:", data);
    const headers = {
      Authorization: await getToken(),
    };
    dispatch(setLoadingloadingOperationURL(true));
    try {
      let response = await fetch(
        API_ENDPOINT + "/qs/v1/embed-url?app_name=IRIS&id=operational_performance&type=dashboard",
        {
          method: "GET",
          headers: headers,
        }
      );
      response = await response.json();
      //console.log("getPalletArchiveEarliestDate API response:", response);
      if (response.embedUrl[0] !== null) {
            dispatch(setOperationsPerformanceURL(response.embedUrl[0]));
            dispatch(setLoadingloadingOperationURL(false));
        } else {
            dispatch(setOperationsPerformanceURL(null));
            dispatch(setLoadingloadingOperationURL(false));
        }
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const getUserDetails = createAsyncThunk(
    "WarehouseInsightsThunk/getUserDetails",
    async (data, { dispatch }) => {
        // console.log("getPalletArchiveEarliestDate:", data);
        const headers = {
            Authorization: await getToken(),
        };
        let userId = await getCentralAdminId();
        //routeUrl = `/Central/user/${userId}`;
        dispatch(setLoadingloadingOperationURL(true));
        try {
            let response = await fetch(
                CENTRAL_ADMIN_ENDPOINT + `/Central/user/${userId}`,
                {
                    method: "GET",
                    headers: headers,
                }
            );
            response = await response.json();
            let responseCopy = JSON.parse(JSON.stringify(response))
            Constants.userRole = response.Data.role_name;
            Constants.userApps = response.Data.applications;
            dispatch(setApplicationIdsLoad(true))
            dispatch(setUserRoleName(response.Data.role_name))
            dispatch(setApplicationIds(response.Data.applications));
            return responseCopy;
            //console.log(response);
            //console.log("getPalletArchiveEarliestDate API response:", response);
            /*if (response.embedUrl[0] !== null) {
                dispatch(setOperationsPerformanceURL(response.embedUrl[0]));
                dispatch(setLoadingloadingOperationURL(false));
            } else {
                dispatch(setOperationsPerformanceURL(null));
                dispatch(setLoadingloadingOperationURL(false));
            }*/
        } catch (error) {
            console.error("API error:", error);
        }
    }
);
export const getOperationalPerformanceQEmbedURL = createAsyncThunk(
  "WarehouseInsightsThunk/getOperationalPerformanceQEmbedURL",
  async (data, { dispatch }) => {
    // console.log("getPalletArchiveEarliestDate:", data);
    const headers = {
      Authorization: await getToken(),
    };
    dispatch(setLoadingloadingOperationURL(true));
    try {
      let response = await fetch(
        API_ENDPOINT + "/qs/v1/embed-url?app_name=IRIS&id=operational_performance&type=q",
        {
          method: "GET",
          headers: headers,
        }
      );
      response = await response.json();
      //console.log("getPalletArchiveEarliestDate API response:", response);
      if (response.embedUrl[0] !== null) {
            dispatch(setOperationsPerformanceQURL(response.embedUrl[0]));
            dispatch(setLoadingloadingOperationURL(false));
        } else {
            dispatch(setOperationsPerformanceQURL(null));
            dispatch(setLoadingloadingOperationURL(false));
        }
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const getPickerDetailEmbedURL = createAsyncThunk(
  "WarehouseInsightsThunk/getPickerDetailEmbedURL",
  async (data, { dispatch }) => {
    // console.log("getPalletArchive:", data);
    const headers = {
      Authorization: await getToken(),
    };
    dispatch(setLoadingPickerURL(true));
    try {
        let response = await fetch(
            API_ENDPOINT + "/qs/v1/embed-url?app_name=IRIS&id=picker_detail&type=dashboard",
            {
              method: "GET",
              headers: headers,
            }
       );
      response = await response.json();
      // console.log("getPalletArchive API response:", response);
      if (response.embedUrl[0] !== null) {
            dispatch(setPickerDetailURL(response.embedUrl[0]));
            dispatch(setLoadingPickerURL(false));
        } else {
            dispatch(setPickerDetailURL(null))
            dispatch(setLoadingPickerURL(false));
        }
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const openNewURLAMI = createAsyncThunk(
  "WarehouseInsightsThunk/openNewURLAMI",
  async (data, { dispatch }) => {
    // console.log("getPalletArchive:", data);
    const userToken = {
      token: await getToken(),
      userId: await getCentralAdminId(),
    };
    // Cookies.set("authToken", userToken.token)
    // Cookies.set("loggedInUserId", userToken.userId)
    // Cookies.set('name', 'value', { domain: 'subdomain.site.com' })

    if (data === "routestatus") {
      let endPoint = AMI_API_ENDPOINT + "/dashboard"
      Cookies.set("authToken", userToken.token, { path: '/dashboard', domain: AMI_API_ENDPOINT })
      Cookies.set("loggedInUserId", userToken.userId, { domain: AMI_API_ENDPOINT })
      window.open(endPoint);
    } else if (data === "assets") {
      let endPoint = AMI_API_ENDPOINT + "/assetdashboard"
      Cookies.set("authToken", userToken.token, { domain: endPoint })
      Cookies.set("loggedInUserId", userToken.userId, { domain: endPoint })
      window.open(endPoint);
    } else if (data === "lifts") {
      let endPoint = AMI_API_ENDPOINT + "/liftdashboard"
      Cookies.set("authToken", userToken.token, { domain: endPoint })
      Cookies.set("loggedInUserId", userToken.userId, { domain: endPoint })
      window.open(endPoint);
    } else if (data === "casesperhour") {
      let endPoint = AMI_API_ENDPOINT + "/cphdashboard"
      Cookies.set("authToken", userToken.token, { domain: endPoint })
      Cookies.set("loggedInUserId", userToken.userId, { domain: endPoint })
      window.open(endPoint);
    }
  }
);
