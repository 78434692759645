import { configureStore } from "@reduxjs/toolkit";

import AuthReducer from "./reducers/authentication/AuthReducer";
import PalletReviewReducer from "./reducers/palletReview/PalletReviewReducer";
import PalletArchiveReducer from "./reducers/palletArchive/PalletArchiveReducer";
import DashboardReducer from "./reducers/dashboard/DashboardReducer";
import DockDoorControlReducer from "./reducers/dockDoorControl/DockDoorControlReducer";
import WarehouseInsightsReducer from "./reducers/warehouseInsights/WarehouseInsightsReducer"

import RoutesReducer from "../diot/redux/reducers/Routes/RoutesReducer"
import AssetsReducer from "../diot/redux/reducers/Assets/AssetsReducer";

export const Store = configureStore({
  reducer: {
    auth: AuthReducer,
    palletReview: PalletReviewReducer,
    palletArchive: PalletArchiveReducer,
    dashboard: DashboardReducer,
    dockDoorControl: DockDoorControlReducer,
    warehouseInsightsReducer: WarehouseInsightsReducer,
    Routes: RoutesReducer,
    Assets:AssetsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
