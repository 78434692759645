import axios from 'axios';
import {Auth} from "aws-amplify";
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_AMI_ENDPOINT, // our API base URL
});

const cenralAPI = axios.create({
    baseURL: process.env.REACT_APP_CENTRAL_ADMIN_API, // our API base URL
});

const cphAPI = axios.create({
    baseURL: process.env.REACT_APP_QUICKDB_URL, // our API base URL
});
export const getToken = async () => {
    try {
        const session = await Auth.currentSession();
        return session.accessToken.jwtToken;
    } catch {
        return null;
    }
};

let getCentralAdminId = async () => {
    const loggedInUserId = Cookies.get('loggedInUserId');
    const user = await Auth.currentUserInfo();
    if (user && user.attributes) {
        return user.attributes['custom:central_user_id'];
    }
    return "";
}

//user.attributes['custom:central_user_id']
//'https://deliveryiotdevapi.rehrig.com
// Request interceptor for adding the bearer token
api.interceptors.request.use(
    async(config) => {
        const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
       // const session = await Auth.currentSession();
        //const loggedInUserIDToken = session.accessToken.jwtToken;
       // if (loggedInUserIDToken) {
            config.headers.Authorization = "Bearer "+await getToken();//`Bearer ${loggedInUserIDToken}`;
            console.log("config.headers.Authorization....",config.headers.Authorization)
            config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

cenralAPI.interceptors.request.use(
    async(config) => {
        const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
       // const session = await Auth.currentSession();
        //const loggedInUserIDToken = session.accessToken.jwtToken;
       // if (loggedInUserIDToken) {
            config.headers.Authorization = await getToken();//`Bearer ${loggedInUserIDToken}`;
            //config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

cphAPI.interceptors.request.use(
    async(config) => {
        // const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
        // const session = await Auth.currentSession();
        // const loggedInUserIDToken = session.accessToken.jwtToken;
        // if (loggedInUserIDToken) {
            config.headers.Authorization = await getToken();//`Bearer ${loggedInUserIDToken}`;
            //config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// API endpoints
export const getUser = () => {
    return api.get('/user');
};

export const deleteUser = (userId) => {
    return api.delete(`/user/${userId}`);
};

export const getRouteDetails = (routeId) => {
    let routeUrl = `/v2/routes/${routeId}`;
    return api.get(routeUrl);
};
export const getRouteMapHeartbeats = (routeId) => {
    let routeUrl = `/v2/routes/${routeId}/heartbeats`;
    return api.get(routeUrl);
};

export const getRouteMapDeltaHeartbeats = (routeId) => {
    let routeUrl = `/v2/routes/${routeId}/heartbeats?previousTimeStamp=${new Date().toISOString().split('.')[0]+"Z"}`;
    return api.get(routeUrl);
};

export const getLiftCurrentLocation = (routeId) => {
    let routeUrl = `v2/lifts/location/${routeId}`;
    return api.get(routeUrl);
};

export const getAssetSummaryData = (custId,locId) => {
    let routeUrl = `v2/assets/summary?locationId=${locId}&customerId=${custId}`;
    return api.get(routeUrl);
};

export const getAssetAtStore = (custId,locId,pg) => {
    let routeUrl = `v2/assets/stores?locationId=${locId}&customerId=${custId}&locationStatus=store&pageSize=500&pageNumber=${pg}`;
    return api.get(routeUrl);
};
export const getAssetAtLost = (custId,locId,pg) => {
    let routeUrl = `v2/assets?locationId=${locId}&customerId=${custId}&locationStatus=lost&pageSize=500&pageNumber=${pg}`;
    return api.get(routeUrl);
};
export const deleteRFIDTags = (rfid) => {
    let routeUrl = `v2/assets`;
    return api.delete(routeUrl,{data: {rfid}});
};
export const getUserDetails = async() => {
    /*const loggedInUserId = Cookies.get('loggedInUserId');
    let routeUrl;
    if(loggedInUserId){
        routeUrl = `/Central/user/${loggedInUserId}`;
    }else{
        let userId = await getCentralAdminId();
        routeUrl = `/Central/user/${userId}`;
    }*/
    let userId = await getCentralAdminId();
    let routeUrl = `/Central/user/${userId}`;
    return cenralAPI.get(routeUrl);
};
export const getLostPalletConfig = (custId) => {
    let routeUrl = `v2/assets/getcustomer?customerId=${custId}`;
    return api.get(routeUrl);
};

export const getLostPalletLocConfig = (locId) => {
    let routeUrl = `v2/assets/getlocation?locationId=${locId}`;
    return api.get(routeUrl);
};

export const savePallsetLostCustomerConfig = (custId,req) => {
    let routeUrl = `v2/assets/customer/${custId}`;
    return api.put(routeUrl,req);
};
export const savePallsetLostLocationConfig = (locId,req) => {
    let routeUrl = `v2/assets/location/${locId}`;
    return api.put(routeUrl,req);
};
export const getEmbeddedURL = (appName,dbId,db) => {

    let dbUrl = `qs/v1/embed-url?app_name=${appName}&id=${dbId}&type=${db}`;
    return cphAPI.get(dbUrl);
};
// Export the api instance
export default api;
