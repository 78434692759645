import React from "react";
import { Routes, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import SideNavDrawer from "./components/navigation/SideNavDrawer";
//import {RouteList} from 'duoiotweb';
import RouteDashBoardDetails from "./pages/RouteDashBoardDetails";
import LoadingBar from "./components/Common/LoadingBar";
//import RouteDetails from "./diot/pages/RouteDetails";
//import AssetDashboard from "./diot/pages/AssetDashboard";
import LiftDashboard from "./diot/pages/LiftDashboard";
import LiftMaintenance from "./diot/pages/LiftMaintenance";
import AddMaintenance from "./diot/pages/AddMaintenance";
//import CasesDashBoard from "./diot/pages/CasesDashBoard";
import CPHDashboard from "./diot/pages/CPHDashboard";

//import RouteDashBoard from "./pages/RouteDashBoard";
//import AssetDashBoard from "./pages/AssetDashBoard";
//import LiftDashBoard from "./pages/LiftDashBoard";
//import CasesDashBoard from "./pages/CasesDashBoard";

const PalletReview = React.lazy(() => import("./pages/PalletReview"));
const AuditPallet = React.lazy(() => import("./components/palletReview/AuditPallet"));
const DockDoorStatus = React.lazy(() => import("./pages/DockDoorStatus"));
const DockDoorControl = React.lazy(() => import("./pages/DockDoorControl"));
// const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Dashboard = React.lazy(() => import("./diot/pages/Dashboard"));
const RouteDetails = React.lazy(() => import("./diot/pages/RouteDetails"));
const AssetDashboard = React.lazy(() => import("./diot/pages/AssetDashboard"));
//const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const PalletArchive = React.lazy(() => import("./pages/PalletArchive"));
const TensorInsights = React.lazy(() => import("./pages/TensorInsights"));
const OperationalPerformance = React.lazy(() => import("./pages/OperationalPerformance"));
const PickerDetail = React.lazy(() => import("./pages/PickerDetail"));



export default function AuthRoutes() {
  return (
    <React.Fragment>
      <SideNavDrawer>
        <React.Suspense fallback={<LoadingBar />}>
          <Routes>
            <Route path="/qcbot"  element={<PalletReview />} />
            <Route path="/auditpallet/:inference_id/:dc_id"  element={<AuditPallet />}/>
            <Route path="/dockdoorstatus"  element={<DockDoorStatus />} />
            <Route path="/dockdoorcontrol"  element={<DockDoorControl />}/>
            {/*<Route path="/dashboard"  element={<Dashboard />} />*/}
            <Route path="/palletarchive"  element={<PalletArchive />} />
            <Route path="/vor-gpt"  element={<TensorInsights />} />
            <Route path="/operational-performance"  element={<OperationalPerformance />} />
            <Route path="/picker-detail"  element={<PickerDetail />} />
            {/*<Route path="/routestatus"  element={<RouteDashBoard />} />*/}
            <Route path="/routestatus"  element={<Dashboard />} />
            <Route path="/routedetails"  element={<RouteDetails />} />
            <Route path="/assets"  element={<AssetDashboard />} />
            <Route path="/lifts"  element={<LiftDashboard />} />
            <Route path="/liftMaintenance"  element={<LiftMaintenance />} />
            <Route path="/addMaintenance" exact element={<AddMaintenance />} />
            <Route path="/casesperhour"  element={<CPHDashboard />} />

          </Routes>
        </React.Suspense>
      </SideNavDrawer>
    </React.Fragment>
  );
}
